<template>
	<div class="row">
		<div class="col">
			<div class="h5 my-3" style="text-transform: capitalize;">
				Edit Profile
			</div>
		</div>
		<div class="col-auto my-2">
			<div class="pointer my-2" @click="hideProfileForm">
				<i class="bi bi-chevron-left "></i> Go back
			</div>
		</div>
		<div class="col-12 my-2">
			<label class="small text-secondary">First name*</label>
			<input class="form-control" v-model.trim="profile.fname" />
		</div>
		<div class="col-12 my-2">
			<label class="small text-secondary">Last name*</label>
			<input class="form-control" v-model.trim="profile.lname" />
		</div>
		<div class="col-12 my-2">
			<label class="small text-secondary">Email address*</label>
			<input class="form-control" v-model.trim="profile.email" />
		</div>
		<div class="col-12 my-2">
			<label class="small text-secondary">Phone*</label>
			<input class="form-control" v-model.trim="profile.phone" />
		</div>
		<div class="col-12 my-2">
			<label class="small text-secondary">Gender*</label>
			<select class="form-select" v-model.trim="profile.gender">
				<option>Female</option>
				<option>Male</option>
				<option>Non-Binary</option>
			</select>
		</div>
		<div class="col-12 my-2">
			<label class="small text-muted">Country*</label>
			<select class="form-select" v-model.trim="profile.country">
				<option value="United States of America">United States of America</option>
				<option value="Canada">Canada</option>
				<option value="United Kingdom">United Kingdom</option>
				<option disabled>------</option>
				<option value="Anguilla">Anguilla</option>
				<option value="Antigua & Barbuda">Antigua & Barbuda</option>
				<option value="Argentina">Argentina</option>
				<option value="Aruba">Aruba</option>
				<option value="Australia">Australia</option>
				<option value="Bahamas">Bahamas</option>
				<option value="Barbados">Barbados</option>
				<option value="Belgium">Belgium</option>
				<option value="Belize">Belize</option>
				<option value="Bermuda">Bermuda</option>
				<option value="Bonaire">Bonaire</option>
				<option value="Brazil">Brazil</option>
				<option value="Cayman Islands">Cayman Islands</option>
				<option value="Colombia">Colombia</option>
				<option value="Cuba">Cuba</option>
				<option value="Curaco">Curacao</option>
				<option value="Denmark">Denmark</option>
				<option value="Dominica">Dominica</option>
				<option value="Dominican Republic">Dominican Republic</option>
				<option value="Finland">Finland</option>
				<option value="France">France</option>
				<option value="French Guiana">French Guiana</option>
				<option value="Germany">Germany</option>
				<option value="Ghana">Ghana</option>
				<option value="Greece">Greece</option>
				<option value="Greenland">Greenland</option>
				<option value="Grenada">Grenada</option>
				<option value="Guadeloupe">Guadeloupe</option>
				<option value="Guyana">Guyana</option>
				<option value="Haiti">Haiti</option>
				<option value="Hawaii">Hawaii</option>
				<option value="Honduras">Honduras</option>
				<option value="Hong Kong">Hong Kong</option>
				<option value="Ireland">Ireland</option>
				<option value="Israel">Israel</option>
				<option value="Italy">Italy</option>
				<option value="Jamaica">Jamaica</option>
				<option value="Japan">Japan</option>
				<option value="Korea Sout">Korea South</option>
				<option value="Martinique">Martinique</option>
				<option value="Mexico">Mexico</option>
				<option value="Montserrat">Montserrat</option>
				<option value="Netherlands">Netherlands (Holland, Europe)</option>
				<option value="New Zealand">New Zealand</option>
				<option value="Nicaragua">Nicaragua</option>
				<option value="Nigeria">Nigeria</option>
				<option value="Norway">Norway</option>
				<option value="Portugal">Portugal</option>
				<option value="Puerto Rico">Puerto Rico</option>
				<option value="St Kitts-Nevis">St Kitts-Nevis</option>
				<option value="St Lucia">St Lucia</option>
				<option value="St Maarten">St Maarten</option>
				<option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
				<option value="South Africa">South Africa</option>
				<option value="Spain">Spain</option>
				<option value="Sri Lanka">Sri Lanka</option>
				<option value="Suriname">Suriname</option>
				<option value="Sweden">Sweden</option>
				<option value="Switzerland">Switzerland</option>
				<option value="Trinidad & Tobago">Trinidad & Tobago</option>
				<option value="Turks & Caicos Is">Turks & Caicos Is</option>
				<option value="United Arab Erimates">United Arab Emirates</option>
				<option value="Venezuela">Venezuela</option>
				<option value="Virgin Islands (British)">Virgin Islands (British)</option>
				<option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
				<option disabled>------</option>
				<option>Other</option>
			</select>
		</div>
		<div class="col-12 my-3 d-grid">
			<button class="btn btn-dark" :disabled="!isFormValid">
				Update profile
			</button>
		</div>
		<div class="col-12 my-2 d-grid">
			<button class="btn btn-link text-decoration-none text-dark"
			@click="hideProfileForm">
				Cancel
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'ProfileForm',
	emits: ['setShowProfileForm'],
	computed:{
		isFormValid(){
			return false;
		}
	},
	data(){
		return {
			profile: this.$store.state.userProfile,
		}
	},
	methods:{
		hideProfileForm(){
			window.scrollTo(0, 0);
			this.$emit('setShowProfileForm', false);
		},
	},
	mounted(){

	},
}
</script>