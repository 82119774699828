<template>
	<div class="mb-5" id="pageTop">
		<div class="row mb-5">
			<div class="col-12 col-md-5">
				<ul class="list-group list-group-flush my-3 sticky-top">
					<li class="list-group-item" align="center">
						<img :src="profileImage"
						@error="$event.target.src = `../assets/img/no-profile.png`"
						v-show="userProfile?.id"
						class="border border-0 border-primary img-fluid rounded-circle" />
						<ImageUploader folder="profiles" :baseFilename="userProfile.id" uploadFileType="profile" cacheControl="no-cache" @setDownloadUrl="setDownloadUrl" />
					</li>
				</ul>
			</div>
			<div class="col-12 col-md-7 my-2 p-3">
				<ProfileForm @setShowProfileForm="setShowProfileForm" v-if="userProfile?.email && showProfileForm" />
				<ProfilePageLinks @setShowProfileForm="setShowProfileForm" v-if="!showProfileForm" />
			</div>
		</div>
	</div>
</template>

<script>
import ImageUploader from '@/components/common/ImageUploader';
import ProfileForm from '@/components/profile/ProfileForm';
import ProfilePageLinks from '@/components/profile/ProfilePageLinks';
export default {
	name: "Profile",
	components:{
		ImageUploader, ProfileForm, ProfilePageLinks,
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile;
		},
		profileImage(){ 
			return `https://firebasestorage.googleapis.com/v0/b/app-soca-islands.appspot.com/o/profiles%2F${this.userProfile?.id}.jpg?alt=media`;
		},
	},
	data(){
		return{
			showProfileForm: false,
		}
	},
	methods:{
		setDownloadUrl(){
			window.location.reload();
		},
		setShowProfileForm(val){
			this.showProfileForm = val;
		},
	},

	mounted(){
		this.$store.commit("setPageTitle", "Profile")
	}

}
</script>
