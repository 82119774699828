<template>
	<div class="h5 my-3" style="text-transform: capitalize;">
		My Account - <strong>{{ userProfile.fname }} {{ userProfile.lname }}</strong>
	</div>
	<ul class="list-group mb-4">
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="$emit('setShowProfileForm', true)">
				<div class="col-2" align="center">
					<i class="bi bi-person"></i>
				</div>
				<div class="col">
					Manage my profile
				</div>
			</div>
		</li>
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="$router.push('/messages')">
				<div class="col-2" align="center">
					<i class="bi bi-chat-left-text"></i>
				</div>
				<div class="col">
					Messages
				</div>
				<div class="col-auto" align="center">
					<span class="badge rounded-pill" :class="msgCount > 0 ? 'bg-danger' : 'bg-secondary'"> {{ msgCount }} </span>
				</div>
			</div>
		</li>
		<li class="list-group-item">
			<div class="row my-2 pointer">
				<div class="col-2" align="center">
					<i class="bi bi-bell"></i>
				</div>
				<div class="col">
					Notifications
				</div>
				<div class="col-auto" align="center">
					<span class="badge rounded-pill bg-secondary"> 0 </span>
				</div>
			</div>
		</li>
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="$router.push('/forgot-password')">
				<div class="col-2" align="center">
					<i class="bi bi-lock"></i>
				</div>
				<div class="col">
					Reset password
				</div>
			</div>
		</li>
	</ul>
	<div class="h5">
		My Posts
	</div>
	<ul class="list-group mb-4">
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="$router.push('/crewfinder/list')">
				<div class="col-2" align="center">
					<i class="bi bi-people"></i>
				</div>
				<div class="col">
					Crewfinder
				</div>
				<div class="col-auto" align="center">
					<span class="badge rounded-pill" :class="crewfinderCount > 0 ? 'bg-dark' : 'bg-secondary'"> {{ crewfinderCount }} </span>
				</div>
			</div>
		</li>
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="$router.push('/marketplace/list')">
				<div class="col-2" align="center">
					<i class="bi bi-shop-window"></i>
				</div>
				<div class="col">
					Marketplace
				</div>
				<div class="col-auto" align="center">
					<span class="badge rounded-pill"  :class="marketplaceCount > 0 ? 'bg-dark' : 'bg-secondary'"> {{ marketplaceCount }} </span>
				</div>
			</div>
		</li>
	</ul>
	<div class="h5">
		Support
	</div>
	<ul class="list-group mb-1">
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="$store.dispatch('openExternalLink', { url: 'https://socaislands.com/frequently-asked-questions' })">
				<div class="col-2" align="center">
					<i class="bi bi-question-circle"></i>
				</div>
				<div class="col">
					Help <i class="ms-2 text-primary bi bi-box-arrow-up-right"></i>
				</div>
			</div>
		</li>
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="$store.dispatch('openExternalLink', { url:'https://socaislands.com/about-us' })">
				<div class="col-2" align="center">
					<i class="bi bi-info-circle"></i>
				</div>
				<div class="col">
					About Soca Islands <i class="ms-2 text-primary bi bi-box-arrow-up-right"></i>
				</div>
			</div>
		</li>
	</ul>
	<div class="h5">
		&nbsp;
	</div>
	<ul class="list-group mb-4">
		<li class="list-group-item">
			<div class="row my-2 pointer" @click="btnLogoutClick">
				<div class="col-2" align="center">
					<i class="bi bi-box-arrow-right"></i>
				</div>
				<div class="col">
					Log out
				</div>
			</div>
		</li>
	</ul>
	<div class="col text-secondary small mb-5">
		Version 1.0
	</div>
</template>

<script>
export default {
	name: "ProfilePageLinks",
	emits: ['setShowProfileForm'],
	components:{
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile;
		},
		crewfinderCount(){
			const crewfinder = this.$store.state.crewfinder;
			if(Array.isArray(crewfinder)){
				return (crewfinder.filter((item) => item.author.id == this.userProfile.id)).length;
			}else{
				return 0;
			}
		},
		marketplaceCount(){
			const marketplace =  this.$store.state.marketplace;
			if(Array.isArray(marketplace)){
				return (marketplace.filter((item) => item.author.id == this.userProfile.id)).length;
			}else{
				return 0;
			}
		},
		msgCount(){
			const messages = this.$store.state.messages || [];
			const filter = (msg)=>{
				return !msg.read.includes(this.userProfile.id)
			}
			return messages.filter(filter).length;
		},
	},
	data(){
		return{
			hasProfileImage: false,
			isBusy: false,
			profile: {},
			isPhoneValid: true,
			privateData: {},
		}
	},
	methods:{
		btnLogoutClick(){
			this.$store.dispatch("logOut");
		},
	},

	mounted(){
	}
}
</script>
